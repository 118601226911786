import { React } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import IconMan from "../../../../assets/icons/man-icon.png"
import IconWomen from "../../../../assets/icons/women-icon.png"
import { Accordion, AccordionItem, Avatar, Chip } from "@nextui-org/react";

const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 10,
    },
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Resume({ formState }) {
    return (
        <div className="flex w-full">
            <Accordion variant="shadow" defaultExpandedKeys={["1"]}>
                <AccordionItem
                    key="1"
                    aria-label="Chung Miller"
                    startContent={
                        <Avatar
                            isBordered
                            color="primary"
                            radius="lg"
                            src={
                                formState?.individual?.sexo === "Hombre"
                                    ? IconMan
                                    : IconWomen
                            }
                        />
                    }
                    subtitle={formState?.individual?.camp}
                    title={formState?.individual?.name}
                >
                    <Chip className="mb-4" color="warning" variant="solid">{formState?.individual?.mode}</Chip>
                    <div className="flex">
                        <div className="mx-2">
                            Ci:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.ci}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mx-2">
                            Edad:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.edad}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mx-2">
                            Sexo:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.sexo}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="mx-2">
                            Celular:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.celular}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="mx-2">
                            Iglesia:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.iglesia}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="mx-2">
                            Los días que vas:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.dias}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="mx-2">
                            Bus contratado:
                        </div>
                        <div className="text-amber-700">
                            {formState?.individual?.bus}
                        </div>
                    </div>
                    {formState?.individual?.bus !== "No" && formState?.individual?.bus !== "Vuelta" &&
                        <div className="flex">
                            <div className="mx-2">
                                Salida:
                            </div>
                            <div className="text-amber-700">
                                {formState?.individual?.departure}
                            </div>
                        </div>
                    }

                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default Resume;

import {Result, Button, Alert} from "antd";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {Badge, Tag} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import TagButtonContainer from "./styled-components/TagButtonContainer";
import {Card, CardBody} from "@nextui-org/react";

export default function SuccesSend({
                                       id,
                                       buttonMessage,
                                       notificationFull,
                                       notificationQueqe,
                                       inscriptionPrice,
                                       email,
                                       mercadoPagoLink,
                                       modeReinscription,
                                   }) {
    useEffect(() => {
        handlePayments();
        handlePriceQueqe();
        handleTitlePayment1();
        handleTitlePayment2();
    }, [email, inscriptionPrice]);

    const handleTitle = () => {
        if (notificationFull) return "Inscripción recibida.";
        if (notificationQueqe) return "Te ingresamos a la cola de espera.";
    };
    const handleSubtitle = () => {
        if (notificationFull)
            return "Campamento ALFA 2025.";
        if (notificationQueqe)
            return "A partir del 22/10 podrás recibir un mail en caso de que ingreses en los cupos disponibles.";
    };
    const handleAlertMessage = () => {
        if (notificationFull) return "* Plazo para pago finaliza en 72hs.";
        if (notificationQueqe)
            return "* Te informarémos vía mail cualquier cambio en tu inscripción.";
    };

    const handlePriceQueqe = () => {
        return "Costo inscripción: $" + inscriptionPrice?.price;
    };

    const handlePriceQueqeComponent = () => {
        if (notificationQueqe)
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Badge
                        status="processing"
                        text={handlePriceQueqe()}
                        style={{padding: "10px"}}
                    />
                    <Link to="/">
                        <Button type="primary">Listo</Button>
                    </Link>
                </div>
            );
    };

    const handleModalQueqe = () => {
        if (notificationQueqe) {
            return (
                <Alert
                    description={handleAlertMessage()}
                    type="warning"
                    style={{marginBottom: "20px"}}
                />
            );
        }
    };

    const handleTitlePayment1 = () => {
        const titleModal = "Total: $";
        return titleModal + inscriptionPrice?.price;
    };

    const handleIva = (price) => {
        const iva = price * 0.1;
        return price + iva;
    };

    const handleTitlePayment2 = () => {
        const titleModal = "Total: $";
        return <div>{titleModal + handleIva(inscriptionPrice?.price)}</div>;
    };

    const handlePayments = () => {
        if (notificationFull) {
            return (
                <div style={{padding: "15px"}}>
                    <Badge.Ribbon text="Abitab" color="volcano">
                        <Card title={handleTitlePayment1()} size="small">
                            Te enviamos un mail a: <b>{email}</b> con la información de pago
                            por Abitab.
                            <div style={{padding: "20px"}}>
                                <Tag color="orange"> {handleAlertMessage()}</Tag>
                            </div>
                        </Card>
                    </Badge.Ribbon>
                    <div style={{padding: "10px"}}></div>
                    <Badge.Ribbon text="Mercado Pago" color="blue">
                        <Card title={handleTitlePayment2()} size="small">
                            <TagButtonContainer>
                                <div style={{padding: "5px"}}>
                                    <Tag color="cyan"> 10% Recargo</Tag>
                                </div>
                                <Button type="primary" href={mercadoPagoLink}>
                                    Pagar online (MercadoPago)
                                </Button>
                            </TagButtonContainer>
                        </Card>
                    </Badge.Ribbon>
                </div>
            );
        }
    };
    return (
        <Card>
            <CardBody>
                <Result
                    status="success"
                    title={handleTitle()}
                    subTitle={handleSubtitle()}
                    style={{padding: "25px"}}
                    extra={[
                        /*           handlePayments(),
                                  handleModalQueqe(),
                                  handlePriceQueqeComponent(), */
                    ]}
                /> </CardBody>
        </Card>
    );
}

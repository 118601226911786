import { React, useEffect, useState } from "react";
import ButtonFormContainer from "./styled-components/ButtonFormContainer";
import { Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SuccesSend from "../../../../components/SuccessSend/SuccesSend";
import SendFail from "../../../../components/SendFail";
import Resume from "../ShowResume";
import fetchMercadoPago from "../../hooks/fetchMercadoPago";
import sendInscription from "../../hooks/sendInscription";
import ContactUs from "../../hooks/contactForm";
import SuccesSendReinscription from "../../../../components/SuccessSendReinscription";
import { TYPE_INSCRIPTION } from "../../constants";
import getTypeInscription from "../../hooks/getTypeInscription";
import ShowResumeBulk from "../ShowResumeBulk/ShowResumeBulk";
import { Button } from "@nextui-org/react";
import SkeletonCheckout from "./components/SkeletonCheckout";

const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 10,
    },
};


function Checkout({
    currentStep,
    setStep,
    formState,
    setFormState,
    modeReinscription,
}) {
    const [inscriptionSuccess, setInscriptionSuccess] = useState(false);
    const [idInscription, setIdInscription] = useState("");
    const [loading, setLoading] = useState(false);
    const [failOrder, setFailOrder] = useState(false);
    const [inscriptionFull, setInscriptionFull] = useState(false);
    const [inscriptionQueqe, setInscriptionQueqe] = useState(false);
    const [mercadoPago, setMercadoPago] = useState("");
    const [statusPayment, setStatusPayment] = useState(
        formState?.statusPayment || false
    );

    const isBulk = getTypeInscription(formState) === TYPE_INSCRIPTION.GROUP;

    const onBackButton = () => {
        setStep(currentStep - 1);
    };

    useEffect(() => {
        setMercadoPago(fetchMercadoPago(formState));
    }, []);

    const onSendInscription = async () => {
        setLoading(true);
        const inscriptionCollectionFull = await sendInscription(
            formState,
            modeReinscription
        );
        if (inscriptionCollectionFull.id) {
            await setLoading(false);
            await setInscriptionSuccess(true);
            await setIdInscription(inscriptionCollectionFull.id);
            await setInscriptionFull(true);
            setFormState({ ...formState, price: inscriptionCollectionFull.price });
            ContactUs(formState, inscriptionCollectionFull.price, modeReinscription);
        } else {
            setFailOrder(true);
            setLoading(false);
        }
    };

    return (
        <div className="w-full h-full">
            {loading && (
                <div className="w-full">
                    <SkeletonCheckout />
                </div>
            )}
            {!loading && failOrder && !inscriptionSuccess && isBulk && (
                <SendFail
                    title="Error en inscripción grupal"
                    subTitle="Chequeá que la planilla no contenga errores."
                    // buttonMessage="Listo"
                    id={idInscription}
                />
            )}
            {!loading && failOrder && !inscriptionSuccess && !isBulk && (
                <SendFail
                    title="Ya estás inscripto"
                    subTitle="Si necesitás modificar tu inscripción, por favor comunicate con nosotros."
                    buttonMessage="Listo"
                    id={idInscription}
                />
            )}
            {!loading && !failOrder && inscriptionSuccess && !modeReinscription && (
                <SuccesSend
                    notificationFull={inscriptionFull}
                    notificationQueqe={inscriptionQueqe}
                    buttonMessage="Listo"
                    id={idInscription}
                    inscriptionPrice={formState?.price}
                    email={formState?.individual?.email || formState?.family?.email}
                    mercadoPagoLink={mercadoPago}
                    modeReinscription={modeReinscription}
                ></SuccesSend>
            )}
            {!loading && !failOrder && inscriptionSuccess && modeReinscription && (
                <SuccesSendReinscription
                    notificationFull={inscriptionFull}
                    notificationQueqe={inscriptionQueqe}
                    buttonMessage="Listo"
                    id={idInscription}
                    inscriptionPrice={formState?.price}
                    email={formState?.individual?.email || formState?.family?.email}
                    mercadoPagoLink={mercadoPago}
                    statusPayment={statusPayment}
                ></SuccesSendReinscription>
            )}
            {!inscriptionSuccess && !loading && !failOrder && (
                <div className="flex flex-col md:h-full">
                    {isBulk ? (
                        <ShowResumeBulk formState={formState}></ShowResumeBulk>
                    ) : (
                        <Resume formState={formState}></Resume>
                    )}

                    <ButtonFormContainer className="p-8">
                        <div style={{ padding: "0px 15px" }}>
                            <Button
                                color="primary"
                                radius="full"
                                size="md"
                                onClick={onBackButton}
                            >Volver</Button>
                        </div>
                        <div style={{ padding: "0px 15px" }}>
                            <Button
                                color="primary"
                                radius="full"
                                size="md"
                                onClick={onSendInscription}
                            >Confirmar</Button>
                        </div>
                    </ButtonFormContainer>
                </div>
            )}
        </div>
    );
}

export default Checkout;

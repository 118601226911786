/* eslint-disable jsx-a11y/alt-text */
import { React, useEffect, useState } from "react";
import ImageStyled from "./styled-components/ImageStyled";
import Placa from "../../assets/images/afiche_2025.png";
import { Typography } from "antd";
import TitleHomeContainer from "./styled-components/TitleHomeContainer";
import { getMobileOperatingSystem } from "../../utils/getMobileOS";
import ContentHome from "./styled-components/ContentHome";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export const OS = {
    ANDROID: "Android",
    IOS: "iOS",
    UNKNOWN: "unknown",
};

function Home() {
    const isMobile = getMobileOperatingSystem() === OS.IOS;
    const navigateTo = useNavigate();

    return (
        <>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    // background: "linear-gradient(224deg, rgb(144 78 101) 0%, rgb(218, 174, 81) 100%)",
                    height: !isMobile && '100%'
                }}
            >
                <ContentHome>
                    <div className="text-center flex flex-col items-center md:mr-52 md:mt-20">
                        <TitleHomeContainer className="text-4xl mt-7 md:mt-0 md:text-5xl bg-yellow-300 p-4 rounded-2xl shadow-lg">CAMPAMENTOS ALFA <p>2025</p></TitleHomeContainer>
                        <div className="py-7">
                            {/*<DescriptionHome>Ya podés inscribirte!</DescriptionHome>*/}
                            {<div className="text-lg text-whi">¡Próximamente inscripciones abiertas!</div>}
                            {/* <DescriptionHome>Recordá que el pago se hace en el campamento.</DescriptionHome> */}
                        </div>
                        <Button color="warning" style={{ width: "200px" }}
                            onClick={() => navigateTo("/inscription")}>
                            Inscribirse
                        </Button>
                    </div>
                    <div
                        className="flex justify-center transition ease-in-out delay-150 -translate-y-1 hover:scale-110 hover:duration-300"
                    >
                        <ImageStyled isMobile={isMobile} src={Placa}></ImageStyled>
                    </div>
                </ContentHome>

            </div>
        </>
    );
}

export default Home;

import React from "react";
import { Input, Modal, Button, ModalContent, ModalHeader, ModalBody, ModalFooter, Tooltip } from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";
import QuestionIcon from "../../assets/icons/question.svg"
import ErrorIcon from "../../assets/icons/close.png"
import InputKey from "./components/InputKey";
import { TYPE_INSCRIPTION } from "../../modules/inscription/constants";
import SelectModePrivate from "./components/SelectModePrivate";
import { useNavigate } from "react-router-dom";


const MODE_SECTION = {
    CAMP: "Acampante",
    LIDER: "Líder",
    CHANGE: "Other"
};


const PrivateSectionModal = ({
    visible,
    onCloseModal,
    isLoading,
    onFecthKey,
    keyIsInvalid,
    campIsInvalid,
    onSelectCard,
    isPrivateMode,
    setIsPrivateMode,
    campSelected
}) => {
    const navigateTo = useNavigate();

    const onSelectMode = (mode) => {
        if (mode === MODE_SECTION.CAMP) {
            setIsPrivateMode(true);
            onSelectCard(0)
        }
        if (mode == MODE_SECTION.LIDER) {
            onCloseModal();
            onSelectCard(1)
        }
        if (mode === MODE_SECTION.CHANGE) {
            navigateTo("/inscription")

        }
    }


    return (
        <Modal backdrop="blur" isOpen={visible} size="1xl" hideCloseButton>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal className="text-center">Inscripción</TitleModal>
                            <div className="flex items-center gap-x-1 justify-center bg-slate-300 rounded-xl p-2">
                                <p className="text-center">{campSelected}</p>
                                <button onClick={() => onSelectMode(MODE_SECTION.CHANGE)} className="text-sm text-center underline text-md">Cambiar</button>
                            </div>
                            {isPrivateMode && <p className="text-sm text-center mt-3">Ingresá la clave correspondiente a tu iglesia y campamento</p>}
                        </ModalHeader>
                        <ModalBody>
                            {isPrivateMode &&
                                <InputKey
                                    onBack={() => setIsPrivateMode(false)}
                                    isLoading={isLoading}
                                    onFecthKey={onFecthKey}
                                    keyIsInvalid={keyIsInvalid}
                                    campIsInvalid={campIsInvalid}
                                />
                            }
                            {!isPrivateMode &&
                                <SelectModePrivate onSelectMode={onSelectMode} modes={MODE_SECTION} />
                            }
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default PrivateSectionModal;

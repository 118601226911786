import { React, useState } from "react";
import CardsGestion from "./components/CardsGestion";
import { useNavigate } from "react-router-dom";
import SelectTable from "../SelectTable";
import KeysWrapper from "./components/KeysWrapper";


const optionsCards = [
  {
    id: 1,
    title: "Claves por iglesia/campamento",
    items: ["Alta y baja de claves."]
  },
  {
    id: 2,
    title: "Lista de campamentos",
    items: ["Consultar numeros generales por campamento.", "Descargar listas"]
  }
]

function GestionManagement() {
  const navigateTo = useNavigate();
  const [flowSelected, setFlowSelected] = useState(0);

  const onSelect = (flowId) => {
    setFlowSelected(flowId);
  }

  const goHome = () => {
    setFlowSelected(0)
  }

  return (
    <div className="rounded-lg p-3 w-full h-full">
      {flowSelected === 0 && (
        <div>
          <p className="text-2xl font-extrabold text-center my-3">Gestión - ALFA</p>
          {optionsCards.map((option) => (
            <CardsGestion options={option} onSelectType={onSelect} />
          ))}
        </div>
      )}
      {flowSelected === 1 && (
        <KeysWrapper goHome={goHome} />
      )}
      {flowSelected === 2 && (
        <SelectTable goBack={goHome} />
      )}
    </div>
  );
}

export default GestionManagement;

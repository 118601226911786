import React from "react";
import { Input, Button, Tooltip } from "@nextui-org/react";
import QuestionIcon from "../../../../assets/icons/question.svg"
import ErrorIcon from "../../../../assets/icons/close.png"


const InputKey = ({ onBack, isLoading, onFecthKey, keyIsInvalid, campIsInvalid }) => {
    const [keyValue, setKeyValue] = React.useState();
    const [keyHelpOpen, setKeyHelpOpen] = React.useState(false);


    return (
        <div>
            <Input
                label="Clave"
                type="text"
                value={keyValue}
                onChange={({ target }) => {
                    setKeyValue(target.value);
                }}
                css={{ width: "400px", paddingTop: "0px" }}
                isDisabled={isLoading}
            />
            <Tooltip key="default" color="secondary" content="Consulta a tu referente de iglesia" showArrow isOpen={keyHelpOpen}>
                <div
                    className="flex w-1/2  cursor-pointer my-2 bg-blue-700 rounded-lg p-2"
                    onClick={() => setKeyHelpOpen(!keyHelpOpen)}
                >
                    <img src={QuestionIcon} width={20} />
                    <p className="text-center ml-1 text-white">No tengo clave</p>
                </div>
            </Tooltip>
            {keyIsInvalid &&
                <div className="flex w-full justify-center pointer my-2">
                    <img src={ErrorIcon} width={20} />
                    <p className="text-center text-red-600 ml-1">Clave incorrecta</p>
                </div>
            }
            {campIsInvalid &&
                <div className="flex w-full justify-center pointer">
                    <img src={ErrorIcon} width={20} />
                    <p className="text-center text-red-600 ml-1">Campamento incorrecto</p>
                </div>
            }
            <div className="flex w-full justify-center gap-3 mt-6">
                <Button css={{ width: "30px", marginTop: "20px" }} isDisabled={isLoading} color="secondary" onClick={onBack}>
                    Volver
                </Button>
                <Button onClick={() => onFecthKey(keyValue)} css={{ width: "30px", marginTop: "20px" }} isDisabled={!keyValue} isLoading={isLoading} color="secondary">
                    Continuar
                </Button>
            </div>
        </div>
    );
};

export default InputKey;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCFgAiYMtxEHB3eXJdgZXQNb4zUD--Sh6s",
    authDomain: "alfa-2025.firebaseapp.com",
    projectId: "alfa-2025",
    storageBucket: "alfa-2025.firebasestorage.app",
    messagingSenderId: "709196483104",
    appId: "1:709196483104:web:5ef5f315c37342b029ca45"
};

// Initialize Firebase y lo pongo en app
const app = initializeApp(firebaseConfig);

// getData devuelve la conexion con firestore a mi app
export const getData = () => getFirestore(app);

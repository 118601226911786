import { React, useState } from "react";
import { collection, getDoc, doc, addDoc, Timestamp } from "firebase/firestore";
import { getData } from "../../../../firebase";
import { Divider } from "antd";
import SelectTable from "../SelectTable";
import { Button, Input } from "@nextui-org/react";
import { EyeSlashFilledIcon } from "./components/EyeSlashFilledIcon";
import { EyeFilledIcon } from "./components/EyeFilledIcon";
import GestionManagement from "../GestionManagement";

function Hash() {
    const [hash, setHash] = useState("");
    const [hashValid, setHashValid] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const onValidateHash = async () => {
        setLoading(true);
        setWrongPassword(false);
        const snap = await getDoc(doc(getData(), "hash", `${hash}`));

        if (snap.exists()) {
            setHashValid(true);
        } else {
            setHashValid(false);
            setWrongPassword(true);
        }
        setLoading(false);
    };

    const toggleVisibility = () => setIsVisible(!isVisible);

    return (
        <div className="w-full">
            {!hashValid ? (
                <div className="flex flex-col items-center justify-center content-center b mt-10 w-full">
                    <div className="flex flex-col items-center bg-rose-600 rounded-xl shadow-lg p-5">
                        <p className="text-xl text-white font-bold">Gestión</p>
                        <p className="text-white bg-yellow-500 rounded-lg p-2 uppercase">ALFA 2025</p>
                        <Divider className="bg-white"></Divider>
                        <div className="p-1">
                            <div className="flex align-center items-center m-4 w-72 gap-x-3">
                                <Input
                                    label="Contraseña"
                                    // variant="bordered"
                                    placeholder="Ingresar contraseña "
                                    value={hash || ""}
                                    defaultValue={hash || ""}
                                    endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility} aria-label="toggle password visibility">
                                            {isVisible ? (
                                                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            ) : (
                                                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            )}
                                        </button>
                                    }
                                    type={isVisible ? "text" : "password"}
                                    className="max-w-[220px]"
                                    onChange={({ target }) => {
                                        setHash(target.value);
                                    }}
                                />
                                <Button
                                    color="primary"
                                    isDisabled={!hash}
                                    onClick={onValidateHash}
                                    isLoading={loading}
                                    className="my-3"
                                >  
                                    Ingresar
                                </Button>
                            </div>
                            {wrongPassword &&
                                <div className="ml-4">
                                    <p className="text-white">¡Contraseña incorrecta!</p>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            ) : (
                <GestionManagement />
            )}
        </div>
    );
}

export default Hash;

import { useEffect, useState } from "react";
import LeftIcon from "../../../../../../assets/icons/left-icon.svg"
import { users } from "../TableKeysManagement/data";
import TableKeysManagement from "../TableKeysManagement/TableKeysManagement";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, Timestamp } from "firebase/firestore";
import { getData } from "../../../../../../firebase";
import AddKeyModal from "./components/AddKeyModal";
import { enqueueSnackbar } from "notistack";
import PreventKeyDeletionModal from "./components/PreventKeyDeletionModal";
import randomKey from "../../../../utils/randomKey";


function KeysWrapper({ goHome }) {
    const [keyData, setKeyData] = useState([]);
    const [showNewKeyModal, setshowNewKeyModal] = useState(false);
    const [showDeletionKeyModal, setShowDeletionKeyModal] = useState(false);
    const [keyToDelete, setKeyToDelete] = useState();

    //loading
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingInsert, setLoadingInsert] = useState(false);

    useEffect(() => {
        fetchKeys();
    }, [])

    const fetchKeys = async () => {
        const keys = query(
            collection(getData(), "keys")
        );

        const snapKeys = await getDocs(keys);
        const keysData = snapKeys.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date.toDate().toLocaleDateString("en-GB"),
        }));

        setKeyData(keysData);
    }

    const onAddKey = async (data) => {
        setLoadingInsert(true);
        const keyDataToInsert = {
            ...data,
            date: Timestamp.fromDate(new Date()),
        }

        // Reference to the document with custom ID
        const docRef = doc(collection(getData(), "keys"), randomKey(keyDataToInsert.owner, keyDataToInsert.church));

        try {
            await setDoc(docRef, keyDataToInsert);
            setshowNewKeyModal(false)
            enqueueSnackbar("Clave ingresada", "success")
            fetchKeys();
        } catch (error) {
            console.error('Error inserting document:', error);
            enqueueSnackbar("Error", "error")
        }
        setLoadingInsert(false);
    }

    const onFetchDelete = (id) => {
        setKeyToDelete(id);
        setShowDeletionKeyModal(true);
    };

    const onConfirmDelete = async (keyId) => {
        setLoadingDelete(true)
        const documentRef = doc(getData(), "keys", keyId);

        const keyDataCopy = keyData;
        const indexToRemove = keyDataCopy.findIndex(item => item.id === keyId);
        if (indexToRemove !== -1) {
            keyDataCopy.splice(indexToRemove, 1);
            setKeyData(keyDataCopy);
            await deleteDoc(documentRef).then(() => {
                setShowDeletionKeyModal(false);
                fetchKeys();
            }).catch((error) => {
                console.error("Error getting document:", error);
            });
        }
        setLoadingDelete(false)
    };



    return (
        <div className="flex flex-col items-start p-4 h-full">
            <AddKeyModal
                visible={showNewKeyModal}
                // isLoading={loadingKey}
                onConfirm={onAddKey}
                onCloseModal={() => setshowNewKeyModal(false)}
                onSaveKey={onAddKey}
                isLoading={loadingInsert}
            />
            <PreventKeyDeletionModal
                visible={showDeletionKeyModal}
                // isLoading={loadingKey}
                onConfirm={onConfirmDelete}
                keyToDelete={keyToDelete}
                onCloseModal={() => setShowDeletionKeyModal(false)}
                isLoading={loadingDelete}
            />
            <div className="flex flex-col md:flex-row gap-x-2 items-center mb-8 w-full md:w-2/4 justify-center">
                <button onClick={goHome} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <img src={LeftIcon} width={30} alt="lefticon" />
                    <p className="mx-1">Inicio</p>
                </button>
                <p className="text-4xl font-bold">Gestión de claves</p>
            </div>
            <TableKeysManagement
                goBack={goHome}
                data={keyData}
                onNewKey={() => setshowNewKeyModal(true)}
                onDelete={onFetchDelete}
            />
        </div >
    );
}

export default KeysWrapper;

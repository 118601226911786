import {React, useState} from "react";
import EditFormGroupal from "../EditFormGroupal";
import Checkout from "../Checkout";

function Bulk({currentStep, setStep, formState, modeReinscription, keyData}) {
    const [wholeFormState, setWholeFormState] = useState(formState || {});
    
    return (
        <div>
            {(currentStep == 0 || currentStep == 1) && (
                <EditFormGroupal
                    currentStep={currentStep}
                    setStep={setStep}
                    formState={wholeFormState}
                    setFormState={(groupalData) => {
                        setWholeFormState({
                            ...wholeFormState,
                            groupal: groupalData,
                        });
                    }}
                    keyData={keyData}
                ></EditFormGroupal>
            )}
            {currentStep == 2 && (
                <div className="h-full">
                    <Checkout
                        currentStep={currentStep}
                        setStep={setStep}
                        formState={wholeFormState}
                        setFormState={(price) => {
                            setWholeFormState({
                                ...wholeFormState,
                                price: price,
                            });
                        }}
                        modeReinscription={modeReinscription}
                    />
                </div>

            )}
        </div>
    );
}

export default Bulk;

import React from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Tooltip } from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";


const PreventDeletion = ({ visible, onCloseModal, isLoading, onConfirm, inscription }) => {
    const [keyValue, setKeyValue] = React.useState();

    const onDelete = () => {
        onConfirm(
            { id: inscription.id, groupId: inscription.groupId, isGroupal: inscription.type === "GROUP", mode: inscription.mode }
        )
    }

    return (
        <Modal backdrop="blur" isOpen={visible} size="1xl" hideCloseButton>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal className="text-center">Eliminar</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex gap-x-1 w-full justify-center">
                                <p className="text-center">¿Seguro que queres eliminar a: </p>
                                <p className="text-center font-extrabold underline">{inscription?.name}</p>
                                <p className="text-center">?</p>
                            </div>
                            <div className="flex w-full justify-center gap-3 mt-4">
                                <button className="bg-slate-500 rounded-xl" isDisabled={isLoading} onClick={onCloseModal}>
                                    <p className="text-white p-3">
                                        Cancelar
                                    </p>
                                </button>
                                <button
                                    onClick={onDelete}
                                    className="bg-red-600 rounded-xl"
                                    isDisabled={!keyValue}
                                    isLoading={isLoading}
                                >
                                    <p className="text-white p-3">
                                        Si, eliminar
                                    </p>
                                </button>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default PreventDeletion;

import React from "react";
import { Input, Button, Tooltip } from "@nextui-org/react";
import ArrowRight from "../../../../assets/icons/arrow-right.svg"
import KeyIcon from "../../../../assets/icons/key-icon.svg"


const SelectModePrivate = ({ onSelectMode, modes }) => {

    const fetchMode = (mode) => {
        onSelectMode(mode);
    }

    return (
        <div className="flex flex-col gap-y-2">
            <div onClick={() => fetchMode(modes.CAMP)} className="flex  gap-x-2 bg-yellow-500 p-4 rounded-lg cursor-pointer hover:bg-yellow-400"><p>Gestionar grupo de acampantes</p> <img src={KeyIcon} width={20} /></div>
            <div onClick={() => fetchMode(modes.LIDER)} className="flex  gap-x-2 bg-yellow-500 p-4 rounded-lg cursor-pointer hover:bg-yellow-400"><p>Inscribirme como líder o servicio</p> <img src={ArrowRight} width={20} /></div>
        </div>
    );
};

export default SelectModePrivate;

import { createGlobalStyle } from "styled-components";

// language=LESS
const GlobalStyle = createGlobalStyle`

  html,
  body {
    font-family: 'Poppins', serif;
    font-size: 14px;
    height: 100%;
    padding: 0;
    margin: 0 !important;
    background: linear-gradient(224deg, rgb(174 117 95) 0%, rgb(235 188 101) 100%);

    button:focus,
    input:focus,
    textArea:focus,
    a:focus {
      outline: none;
    }

    & a,
    a:hover {
      text-decoration: none;
    }
  }

  #alfa-app {
    height: 100%;
    width: 100%;
  }

  .ReactModal__Body--open #courier-app {
    filter: blur(2px);
  }


  .hide-info .gm-style-iw-a {
    display: none;
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px; 
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(176, 176, 176);
  }
`;

export default GlobalStyle;

import { createContext, useEffect, useState } from "react";

const InscriptionContext = createContext({});

const InscriptionProvider = ({ children }) => {
    const [campSelected, setCampSelected] = useState();
    const [inscriptionKey, setInscriptionKey] = useState();

    const fetchKey = (key) => {
        setInscriptionKey(key);
    };
    const fetchCam = (camp) => {
        setCampSelected(camp);
    };
    const clear = () => {
        setCampSelected("");
    };
    const clearKey = () => {
        setInscriptionKey("");
    };

    return (
        <InscriptionContext.Provider value={{ clear, clearKey, fetchCam, campSelected, fetchKey, inscriptionKey }}>
            {children}
        </InscriptionContext.Provider>
    );
}

export { InscriptionContext, InscriptionProvider };

import { React, useContext, useEffect, useState } from "react";
import { collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import UpdateIcon from "../../../../assets/icons/update.svg";
import { Card, CardHeader, Spinner } from "@nextui-org/react";
import { getData } from "../../../../firebase";
import { MODE_INSCRIPTION, TYPE_INSCRIPTION } from "../../constants";
import { InscriptionContext } from "../../../../provider/inscription";
import getCampFromDatabase from "../../hooks/getCampFromDatabase";
import TableAutogestion from "./components/TableAutogestion";
import SkeletonAutogestion from "./components/SkeletonAutogestion";
import PreventDeletion from "./components/PreventDeletionModal";

function Autogestion() {
    const [individualCamp, setIndividualCamp] = useState([]);
    const [individualLeader, setIndividualLeader] = useState([]);
    const [individualOther, setIndividualOther] = useState([]);
    const [busQuantityGoing, setBusQuantityGoing] = useState([]);
    const [busQuantityReturn, setbusQuantityReturn] = useState([]);
    const [mixedModeRows, setMixedModeRows] = useState([]);
    const [mookGroupCampState, setMookGroupCampState] = useState([]);

    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [inscriptionToDelete, setInscriptionToDelete] = useState();

    const { campSelected, inscriptionKey } = useContext(InscriptionContext);


    const [refresh, setRefresh] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const isMixedMode = true;

    useEffect(() => {
        const modeCamp = isMixedMode ? mixedModeRows : individualCamp;
        const tableOnFilter = [
            ...modeCamp,
            ...individualLeader,
            ...individualOther,
        ];
        let busQuantityGoing = 0;
        let busQuantityReturn = 0;
        tableOnFilter.map((x) => {
            if (
                x.bus &&
                x.bus === "Ida y Vuelta" &&
                x.departure !== "Transporte propio"
            ) {
                busQuantityGoing++;
                busQuantityReturn++;
            }
            if (x.bus && x.bus === "Ida" && x.departure !== "Transporte propio") {
                busQuantityGoing++;
            }
            if (x.bus && x.bus === "Vuelta" && x.departure !== "Transporte propio") {
                busQuantityReturn++;
            }
        });
        setBusQuantityGoing(busQuantityGoing);
        setbusQuantityReturn(busQuantityReturn);
    }, [individualCamp, individualLeader, individualOther, mixedModeRows]);

    const hasRows =
        individualCamp.length > 0 ||
        individualLeader.length > 0 ||
        individualOther.length > 0 ||
        mixedModeRows.length > 0;

    const getRowsGroupalsIndividuals = (group, individual, leader) => {
        return [...group, ...individual, ...leader];
    };

    const onFetchDelete = (inscription) => {
        setShowDeletionModal(true);
        setInscriptionToDelete(inscription);
    };

    const onConfirmDelete = async (inscriptionData) => {
        setShowDeletionModal(false);

        const documentRef = doc(getData(), getCampFromDatabase(campSelected), inscriptionData.isGroupal ? inscriptionData.groupId : inscriptionData.id);

        if (inscriptionData.isGroupal) {
            const mookGroupCampStateCopy = mookGroupCampState;
            const indexToRemove = mookGroupCampStateCopy.findIndex(item => item.id === inscriptionData.id);
            if (indexToRemove !== -1) {
                mookGroupCampStateCopy.splice(indexToRemove, 1);
            }

            const updateData = {
                groupal: mookGroupCampStateCopy,
            };

            await updateDoc(documentRef, updateData).then((result) => {
                setMookGroupCampState(mookGroupCampStateCopy)
                setMixedModeRows(
                    getRowsGroupalsIndividuals(mookGroupCampStateCopy, individualCamp, individualLeader)
                );
            }).catch((error) => {
                console.error("Error getting document:", error);
            });
        }
        else {
            const isModeCamp = inscriptionData.mode === "Acampante";
            const inscriptionsCopy = isModeCamp ? individualCamp : individualLeader;
            const indexToRemove = inscriptionsCopy.findIndex(item => item.id === inscriptionData.id);
            if (indexToRemove !== -1) {
                inscriptionsCopy.splice(indexToRemove, 1);

                await deleteDoc(documentRef).then(() => {
                    if (isModeCamp) {
                        setIndividualCamp(inscriptionsCopy);
                        setMixedModeRows(
                            getRowsGroupalsIndividuals(mookGroupCampState, inscriptionsCopy, individualLeader)
                        );
                    } else {
                        setIndividualLeader(inscriptionsCopy);
                        setMixedModeRows(
                            getRowsGroupalsIndividuals(mookGroupCampState, individualCamp, inscriptionsCopy)
                        );
                    }

                }).catch((error) => {
                    console.error("Error getting document:", error);
                });
            }
        }
    };

    const handleOnClickUpdate = () => {
        setLoadingData(true);
        setRefresh(!refresh);
    };

    useEffect(() => {
        const getInscriptos = async () => {
            const campFromBd = getCampFromDatabase(campSelected)
            const inscriptionColection = collection(getData(), `${campFromBd}`);

            const inscriptionSnapshot = await getDocs(inscriptionColection);

            const inscriptionListF = inscriptionSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            //Inscripcion por Excel.
            const resultGroupCamp = inscriptionListF.filter(
                (bookId) => bookId.type === TYPE_INSCRIPTION.GROUP
            );

            const mookGroupCamp = [];

            resultGroupCamp.forEach((group) => {
                group.groupal.forEach((element) => {
                    if (element.key === inscriptionKey)
                        mookGroupCamp.push({
                            bus: element.bus,
                            camp: element.camp,
                            comment: element.comment,
                            departure: element.departure,
                            dias: element.dias,
                            edad: element.edad,
                            iglesia: element.iglesia,
                            name: element.name,
                            sexo: element.sexo,
                            mode: element.mode,
                            owner: element.owner,
                            date: group.date.toDate().toLocaleDateString("en-GB"),
                            dateOrder: group.date.toDate(),
                            id: element.id,
                            key: element.key,
                            groupId: group.id,
                            ci: element.ci,
                            type: "GROUP",
                        });
                });
            });

            const resultIndividualCamp = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    bookId.individual.mode === MODE_INSCRIPTION.ACAMPANTE &&
                    bookId.individual.key === inscriptionKey
            );

            const mookIndividualCamp = resultIndividualCamp.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            const resultIndividualLeader = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    bookId.individual.mode === MODE_INSCRIPTION.LIDER &&
                    bookId.individual.key === inscriptionKey
            );

            const mookIndividualLeader = resultIndividualLeader.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            const resultIndividualOther = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    [MODE_INSCRIPTION.SERVICIO, MODE_INSCRIPTION.HIJO_LS].includes(
                        bookId.individual.mode
                    )
            );

            const mookIndividualOther = resultIndividualOther.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            if (isMixedMode) {
                setMixedModeRows(
                    getRowsGroupalsIndividuals(mookGroupCamp, mookIndividualCamp, mookIndividualLeader)
                );
            }

            setMookGroupCampState(mookGroupCamp)

            setIndividualCamp(mookIndividualCamp);

            setIndividualLeader(mookIndividualLeader);

            setIndividualOther(mookIndividualOther);

            setLoadingData(false);

        };

        setLoadingData(true);
        getInscriptos();

        // array vacio, se ejecuta cuando se monta <app />
    }, [refresh]);

    if (loadingData) {
        return (
            <div className="flex">
                <SkeletonAutogestion />
            </div>
        )
    }

    return (
        <div className="flex flex-col items-center mt-4">
            <PreventDeletion
                visible={showDeletionModal}
                // isLoading={loadingKey}
                onConfirm={onConfirmDelete}
                inscription={inscriptionToDelete}
                onCloseModal={() => setShowDeletionModal(false)}
            />
            <div className="flex flex-col">
                <div className="flex flex-col justify-center w-full items-center">
                    <div className="flex items-center">
                        <div className="flex flex-col md:flex-row items-center mb-3">
                            <div className="flex w-full items-center justify-center text-2xl gap-x-2 mb-4 md:mb-0">
                                <p className=""> Campamento:</p> <p className="uppercase font-bold bg-amber-300 rounded-2xl p-2">{campSelected}</p>
                                {!loadingData && <img onClick={handleOnClickUpdate} className="cursor-pointer" src={UpdateIcon} alt="update" width={25}></img>}
                                {loadingData && <Spinner color="primary" />}

                            </div>
                            <div className="flex gap-x-1 text-sm ml-4 items-center">
                                <p>Referencia:</p>
                                <p className="underline">{inscriptionKey}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-center w-full md:max-w-4xl">
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Acampantes:</p>
                                <h4 className="font-bold text-large">{isMixedMode ? mixedModeRows.length : individualCamp.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Líderes:</p>
                                <h4 className="font-bold text-large">{individualLeader.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Otros:</p>
                                <h4 className="font-bold text-large">{individualOther.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Bus IDA:</p>
                                <h4 className="font-bold text-large">{busQuantityGoing}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="flex py-4 m-1 md:w-110 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Bus VUELTA:</p>
                                <h4 className="font-bold text-large">{busQuantityReturn}</h4>
                            </CardHeader>
                        </Card>

                        <Card className="py-4 m-4 bg-amber-300 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold text-center">Total:</p>
                                <h4 className="font-bold text-large text-blue-600">{
                                    (isMixedMode ? mixedModeRows.length : individualCamp.length) +
                                    individualLeader.length +
                                    individualOther.length
                                }</h4>
                            </CardHeader>
                        </Card>
                    </div>
                </div>

            </div>
            <div className="bg-white w-full p-3 overflow-x-scroll">
                {mixedModeRows.length > 0 && <TableAutogestion users={mixedModeRows} onDelete={onFetchDelete} />}
                {mixedModeRows.length === 0 && <p className="bg-red-300 rounded-lg p-2 text-center">No hay registros</p>}
            </div>
        </div>
    );
}

export default Autogestion;

import NavBar from "./components/NavBar/NavBar";
import { React, useEffect } from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import "antd/dist/antd.css";
import Home from "./components/Home/Home";
import styled from "styled-components";
import Footer from "./components/Footer/Footer";
import GlobalStyleSheet from "./styles";
import About from "./pages/About/About";
import Hash from "./modules/inscriptionCenter/components/Hash";
import './newStyles.css'
import { NextUIProvider } from "@nextui-org/react";
import InscriptionManager from "./modules/inscription/components/InscriptionType";
import SelectCamp from "./modules/inscription/components/SelectCamp";
import GeneralInscription from "./modules/inscription/components/GeneralInscription";
import { SnackbarProvider } from "notistack";
import { InscriptionProvider } from "./provider/inscription";


const RouterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
`;

const images = [
    { src: '../src/assets/images/afiche_2025.png' },
    { src: '../src/assets/images/previewCardGroupal.png' },
    { src: '../src/assets/images/previewGeneralCard.png' }
];

function App() {
    const preLoadImages = () => {
        return (
            <div className="hidden">
                {images.map((img) => (
                    <img src={img.src} alt={img.src} />
                ))}
            </div>
        )
    }

    // Preload images
    useEffect(() => {
        const imgs = images.map((img) => new Image());
        imgs.forEach((img) => {
            img.src = img.src;
        });
    }, []);

    return (
        <InscriptionProvider>
            <NextUIProvider>
                <SnackbarProvider
                    maxSnack={2}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <GlobalStyleSheet />
                    <div className="flex flex-col justify-between w-full h-screen">
                        <Router>
                            <RouterContainer>
                                <NavBar />
                                <Routes>
                                    <Route
                                        path="/"
                                        exact
                                        element={
                                            <Home />
                                        }>
                                    </Route>
                                    <Route
                                        exact
                                        path="/about"
                                        element={
                                            <About />
                                        }>
                                    </Route>
                                    <Route
                                        exact path="/inscription"
                                        element={
                                            <SelectCamp />
                                        }>
                                    </Route>
                                    <Route
                                        exact path="/inscription/type"
                                        element={
                                            <InscriptionManager />
                                        }>
                                    </Route>
                                    <Route
                                        exact path="/general"
                                        element={
                                            <GeneralInscription />
                                        }>
                                    </Route>
                                    <Route
                                        exact
                                        path="/gestion" element={
                                            <Hash />
                                        }>
                                    </Route>
                                </Routes>
                            </RouterContainer>
                        </Router>
                        <Footer></Footer>{" "}
                    </div>
                </SnackbarProvider>
            </NextUIProvider>
            {preLoadImages()}
        </InscriptionProvider>
    );
}

export default App;

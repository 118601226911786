import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Tooltip, Select, SelectItem, Input, Button } from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";
import { CAMPS, CHURCHS } from "../../../../../../../inscription/constants";


const AddKeyModal = ({ visible, onCloseModal, isLoading, onSaveKey }) => {
    const [keyValue, setKeyValue] = useState();
    const [listCampsSelected, setListCampsSelected] = useState(new Set(['']));
    const [listChurch, setListChurch] = useState(new Set(['']));
    const [otherChurch, setOtherChurch] = useState();
    const [keyDataState, setKeyDataState] = useState();

    useEffect(() => { console.log(keyDataState) }, [keyDataState])

    const formIsValid = !!keyDataState?.camp && !!keyDataState?.church && !!keyDataState?.owner


    const onDelete = () => {
        /* onConfirm(
            { id: inscription.id, groupId: inscription.groupId, isGroupal: inscription.type === "GROUP" }
        ) */
    }
    const onCancel = () => {
        setKeyDataState("")
        setListChurch(new Set(['']))
        setListCampsSelected(new Set(['']))
        onCloseModal()
    }

    const handleSelectionChangeCamp = (e) => {
        const campSelected = e.target.value;
        setListCampsSelected(new Set([campSelected]));

        setKeyDataState({
            ...keyDataState,
            camp: campSelected,
        });
    };

    const handleSelectionChangeChurch = (e) => {
        const churchSelected = e.target.value;
        setListChurch(new Set([churchSelected]));

        if (churchSelected !== "Otra")
            setKeyDataState({
                ...keyDataState,
                church: churchSelected,
            });
        else {
            setKeyDataState({
                ...keyDataState,
                church: "",
            });
        }
        setOtherChurch(churchSelected === "Otra");
    };

    return (
        <Modal backdrop="blur" isOpen={visible} size="1xl" hideCloseButton>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal className="text-center">Nueva clave</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex flex-col gap-x-1 w-full justify-center">
                                <Select
                                    isRequired
                                    label="Campamento"
                                    className="my-3"
                                    onChange={handleSelectionChangeCamp}
                                    // defaultSelectedKeys={[formState?.individual?.sexo]}
                                    selectedKeys={listCampsSelected}
                                // disabledKeys={getDisabledCamps()}
                                >
                                    {CAMPS?.map((camp) => (
                                        <SelectItem key={camp.name} value={camp.name}>
                                            {camp.name}
                                        </SelectItem>
                                    ))}
                                </Select>

                                <Select
                                    isRequired
                                    label="Iglesia"
                                    placeholder="Seleccionar iglesia"
                                    className="my-3"
                                    onChange={handleSelectionChangeChurch}
                                    selectedKeys={listChurch}
                                >
                                    {CHURCHS.map((church) => (
                                        <SelectItem key={church} value={church}>
                                            {church}
                                        </SelectItem>
                                    ))}
                                </Select>

                                {otherChurch && (
                                    <Input
                                        // defaultValue={keyDataState.chur}
                                        type="text"
                                        variant="flat"
                                        label="Nombre de la Iglesia"
                                        onChange={({ target }) => {
                                            setKeyDataState({
                                                ...keyDataState,
                                                church: target.value,
                                            });
                                        }}
                                    />
                                )}
                                <Input
                                    // defaultValue={keyDataState.chur}
                                    type="text"
                                    variant="flat"
                                    label="Responsable"
                                    onChange={({ target }) => {
                                        setKeyDataState({
                                            ...keyDataState,
                                            owner: target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="flex w-full justify-center gap-3 mt-4">
                                <Button className="bg-slate-500 rounded-xl" isDisabled={isLoading} onClick={onCancel}>
                                    <p className="text-white p-3">
                                        Cancelar
                                    </p>
                                </Button>
                                <Button
                                    onClick={() => onSaveKey(keyDataState)}
                                    className="bg-red-600 rounded-xl"
                                    isDisabled={!formIsValid}
                                    isLoading={isLoading}
                                >
                                    <p className="text-white p-3">
                                        Guardar
                                    </p>
                                </Button>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default AddKeyModal;

import { Card, Skeleton } from "@nextui-org/react";


function SkeletonCheckout() {
    return (
        <Card className="space-y-5 p-4" radius="lg">
            <div className="max-w-[300px] w-full flex items-center gap-3">
                <div>
                    <Skeleton className="flex rounded-full w-12 h-12" />
                </div>
                <div className="w-full flex flex-col gap-2">
                    <Skeleton className="h-3 w-3/5 rounded-lg" />
                    <Skeleton className="h-3 w-4/5 rounded-lg" />
                </div>
            </div>
            <div className="space-y-3">
                <Skeleton className="w-1/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary-300"></div>
                </Skeleton>
                <Skeleton className="w-1/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary-200"></div>
                </Skeleton>
            </div>
            <div className="space-y-3">
                <Skeleton className="w-1/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary-300"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary-200"></div>
                </Skeleton>
                <Skeleton className="w-1/5 rounded-lg">
                    <div className="h-3 w-full rounded-lg bg-secondary-200"></div>
                </Skeleton>
            </div>
        </Card>
    );
}

export default SkeletonCheckout;

function CardsCamp({ camp, onSelectCamp }) {

    return (
        <div className="w-full px-4 md:max-w-lg">
            <div class="flex items-center justify-between shadow-lg bg-amber-50 rounded-lg md:transition-all md:duration-500 md:hover:scale-105 md:cursor-pointer my-2">
                <div class="bg-blue-600 text-center p-6 w-1/2">
                    <h3 class="text-2xl text-white mb-1">{camp.name}</h3>
                    <span class="px-4 py-1 text-lg font-semibold text-white bg-orange-400 rounded-lg ml-3">${camp.price}</span>
                </div>
                <div className="pr-3">
                    <button onClick={()=> onSelectCamp(camp.id)} type="button" class="px-5 py-2.5 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-full">Seleccionar</button>
                </div>
            </div>
        </div>
    );
}

export default CardsCamp;

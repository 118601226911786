
function filterIdsDuplicates(array) {
    return array.reduce((acumulador, elemento) => {
        if (!acumulador.some(el => el.id === elemento.id)) {
            acumulador.push(elemento);
        }
        return acumulador;
    }, []);
}

export default filterIdsDuplicates;
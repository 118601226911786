export const TYPE_INSCRIPTION = {
    INDIVIDUAL: "INDIVIDUAL",
    MATRIMONIAL: "MATRIMONIAL",
    GROUP: "GROUP",
    AUTOGESTION: "AUTOGESTION",
};

export const MODE_INSCRIPTION = {
    ACAMPANTE: "Acampante",
    LIDER: "Líder",
    SERVICIO: "Servicio",
    HIJO_LS: "Hijo/a líder o servicio",
};

export const CHURCHS = [
    "Arrieta",
    "Buceo",
    "Barros Blancos",
    "Chuy",
    "Colonia",
    "Carmelo",
    "Colón",
    "Cerro",
    "Dolores",
    "Ecilda",
    "El Carmen",
    "Espronceda",
    "Faro de la Costa",
    "Progreso",
    "Ituzaingó",
    "Juan Lacaze",
    "La Espada",
    "La Teja",
    "La Unión",
    "Marindia",
    "Maldonado",
    "Manga",
    "Mercedes",
    "Marcos Salcedo",
    "Minas",
    "Piriapolis",
    "Paysandú",
    "San Carlos",
    "Rosario",
    "Rocha",
    "Reducto",
    "Salto",
    "San José",
    "Tupambaé",
    "Tacuarembó La Cruz",
    "Tacuarembó Barrio López",
    "Tararias",
    "Trinidad",
    "km 73",
    "Otra",
];

export const INSCRIPTION_DIRECTION = {
    INSCRIPTION: "INSCRIPTION",
    RE_INSCRIPTION: "RE_INSCRIPTION",
    DELETE: "DELETE",
};

export const CAMP = {
    CAMP_1: "Niños",
    CAMP_2: "Pre Adolescentes",
    CAMP_3: "Adol A",
    CAMP_4: "Adol B",
    CAMP_5: "Jóvenes A",
    CAMP_6: "Jóvenes B",
    CAMP_7: "Antiestrés",
    CAMP_8: "Familias",
};

export const CAMP_BD = {
    CAMP_1: "niños",
    CAMP_2: "pre_adol",
    CAMP_3: "adol_a",
    CAMP_4: "adol_b",
    CAMP_5: "jov_a",
    CAMP_6: "jov_b",
    CAMP_7: "antiestres",
    CAMP_8: "familias",
};

export const GENERAL_CAMPS = ['CAMP_6','CAMP_7','CAMP_8']

export const CAMPS = [
    {
        id: "CAMP_1",
        name: "Niños",
        aviable: true,
        price: 2400
    },
    {
        id: "CAMP_2",
        name: "Pre Adolescentes",
        aviable: true,
        price: 2400
    },
    {
        id: "CAMP_3",
        name: "Adol A",
        aviable: true,
        price: 3300
    },
    {
        id: "CAMP_4",
        name: "Adol B",
        aviable: true,
        price: 3300
    },
    {
        id: "CAMP_5",
        name: "Jóvenes A",
        aviable: true,
        price: 4000
    },
    {
        id: "CAMP_6",
        name: "Jóvenes B",
        aviable: true,
        price: 4000
    },
    {
        id: "CAMP_7",
        name: "Antiestrés",
        aviable: true,
        price: 3900
    },
    {
        id: "CAMP_8",
        name: "Familias",
        aviable: true,
        price: 2550
    },
];

export const CAMPS_BULK = [
    {
        id: "CAMP_1",
        name: "Niños",
        aviable: true
    },
    {
        id: "CAMP_2",
        name: "Pre Adolescentes",
        aviable: true
    },
    {
        id: "CAMP_3",
        name: "Adol A",
        aviable: true
    },
    {
        id: "CAMP_4",
        name: "Adol B",
        aviable: true
    },
    {
        id: "CAMP_8",
        name: "Familias",
        aviable: true
    },
];

export const BUS_OPTIONS = [
    {
        id: "ida",
        name: "Ida",
        value: "Ida",
    },
    {
        id: "vuelta",
        name: "Vuelta",
        value: "Vuelta",
    },
    {
        id: "ida_vuelta",
        name: "Ida y Vuelta",
        value: "Ida y Vuelta",
    },
    {
        id: "none_bus",
        name: "No voy en bus",
        value: "No",
    },
];
export const TYPE_OPTIONS = [
    {
        id: "camp",
        name: "Acampante",
    },
    {
        id: "leader",
        name: "Líder",
    },
    {
        id: "service",
        name: "Servicio",
    },
    {
        id: "child_ls",
        name: "Hijo/a líder o servicio",
    },
];
export const DEPARTURE_OPTIONS = [
    {
        id: "zone_1",
        name: "Espada",
    },
    {
        id: "zone_2",
        name: "Manga",
    },
    {
        id: "zone_3",
        name: "Reducto",
    },
    {
        id: "zone_4",
        name: "Ituzaingó",
    },
    {
        id: "zone_6",
        name: "IBA",
    },
    {
        id: "zone_7",
        name: "Bulevar y Gral Flores",
    },
    {
        id: "zone_5",
        name: "Transporte propio",
    },
];

export const TOPE = {
    TOPE_VALUE: 200,
    TOPE_VALUE_ALFA: 220,
};

export const MODE = [
    {
        id: "todos",
        value: "Todos",
    },
    {
        id: 1,
        value: "1 Día",
    },
    {
        id: 2,
        value: "2 Días",
    },
    {
        id: 3,
        value: "3 Días",
    },
    {
        id: 4,
        value: "4 Días",
    },
    {
        id: 5,
        value: "5 Días",
    },
];

export const PRICING = {
    INDIVIDUAL: {
        CAMP_4: 300,
        CAMP_5: 400,
        CAMP_6: 500,
        CAMP_7: 600,
        CAMP_8: 700,
    },
    BUS: {
        BUS_PRICE: 600,
    },
};

export const BUS_FULL = {
    STATUS: true,
    camps: [
        // {
        //   value: "Niños"
        // },
/*         {
            value: "Jóvenes B"
        },
        {
            value: "Familias"
        } */
    ],
};

import CircleCheckIcon from "../../../../../assets/icons/check-icon.svg"

function CardsGestionBulk({ options, onSelectType }) {

    return (
        <div className="w-full px-4">
            <div class="shadow-lg bg-amber-50 rounded-lg md:transition-all md:duration-500 md:hover:scale-105 md:cursor-pointer my-2 md:h-80">
                <div class="bg-orange-600 text-center p-6">
                    <h3 class="text-2xl text-white mb-1">{options.title}</h3>
                    {/* <span class="px-4 py-1 text-lg font-semibold text-white bg-orange-400 rounded-lg ml-3">${options.price}</span> */}
                </div>
                <div className="flex flex-col justify-between p-2 w-full">
                    <div className="py-3 md:h-36">
                        {options.items.map((option) => (
                            <div className="flex py-1">
                                <img src={CircleCheckIcon} alt="check" width="20px" />
                                <p className="ml-1">{option}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center w-full">
                        <button onClick={() => onSelectType(options.id)} type="button" class="px-5 py-2.5 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-full">Seleccionar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardsGestionBulk;

import { React, useEffect, useState } from "react";
import fileToDownload from "../../../../assets/files/bulk_alfa_template.xlsx";
import ButtonsBulkContainer from "../EditFormGroupal/styled-components/ButtonsBulkContainer";
import { Tag, Badge } from "antd";
import { MODE, CAMPS_BULK, DEPARTURE_OPTIONS, BUS_FULL, CHURCHS, CAMPS } from "../../constants";
import CircleCheckIcon from "../../../../assets/icons/check-icon.svg"
import * as XLSX from "xlsx";
import ButtonFormContainer from "../EditFormGroupal/styled-components/ButtonFormContainer";
import FormContentContainer from "../EditFormGroupal/styled-components/FormContentContainer";
import LoadFileContainer from "./styled-components/LoadFileContainer";
import { Button, Chip, SelectItem, Select as SelectNext, Tooltip } from "@nextui-org/react";
import ExampleFileModal from "./components/ExampleFileModal";
import InputNext from "../EditFormIndividual/styled-components/InputNext";
import { v4 as uuidv4 } from 'uuid';
import filterIdsDuplicates from "./utils";

function EditFormGroupal({ currentStep, setStep, formState, setFormState, keyData }) {
    const [formValid, setFormValid] = useState(false);
    const [viewModalExampleFile, setViewModalExampleFile] = useState(false);
    const [items, setItems] = useState(formState?.groupal || []);
    const [fileName, setFileName] = useState(formState?.groupal?.length > 0 ? formState?.groupal[0]?.fileName : "");
    const [otherChurch, setOtherChurch] = useState(false);
    const [listChurch, setListChurch] = useState(new Set([!!formState?.groupal ? formState?.groupal[0]?.iglesia : '']));
    const [listCampsSelected, setListCampsSelected] = useState(new Set([!!formState?.groupal ? formState?.groupal[0]?.camp : '']));
    const [listDepartureSelected, setListDepartureSelected] = useState(new Set([!!formState?.groupal ? formState?.groupal[0]?.departure : '']));

    const [nextStepTooltipVisible, setNextStepTooltipVisible] = useState(false);



    const [church, setChurch] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.iglesia : ""
    );
    const [owner, setOwner] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.owner : ""
    );
    const [camp, setCamp] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.camp : ""
    );
    const [departure, setDeparture] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.departure : ""
    );

    const [busIsNotAviable, setBusIsNotAviable] = useState(false);


    const fileLoaded = !!items && fileName;

    const readExcel = (file) => {
        setFileName(file.name);
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                setNextStepTooltipVisible(true);
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            const options = d.map(function (row) {
                if (!!row?.ci)
                    return {
                        ci: row.ci,
                        name: row.nombre_apellido,
                        edad: row.edad,
                        sexo: row.sexo,
                        comment: row?.comentarios_comida_medicacion || "",
                    };
            });
            setItems(options.filter(element => element !== undefined));
        });
    };

    const onBackButton = () => {
        setNextStepTooltipVisible(false);
        setStep(currentStep - 1);
    };

    const handleOnClick = () => {
        if (currentStep === 0) {
            setCamp(keyData.camp)
            setChurch(keyData.church);
        }
        if (currentStep === 1) {
            const mookGroupal = items.map((group) => ({
                ...group,
                bus: busIsNotAviable ? "No" : "Ida y Vuelta",
                dias: MODE[0].id,
                mode: "Acampante",
                iglesia: church,
                comment: !!group?.comment ? group?.comment : "ninguno",
                owner: owner,
                camp: camp,
                departure: departure,
                fileName: fileName,
                key: keyData.key,
                id: uuidv4()
            }));
            setFormState([...filterIdsDuplicates(mookGroupal)]);
        }
        setStep(currentStep + 1);
    };

    const areFieldsValid = () => {
        if (currentStep === 0) {
            return !!fileName;
        }
        if (currentStep === 1) {
            console.log(church, camp, departure, owner, busIsNotAviable);
            return (
                !!church && !!camp && (busIsNotAviable ? true : !!departure) && !!owner
            );
        }
    };

    const getCampStatusBus = () => {
        const { camps } = BUS_FULL;
        let encontre = false;
        for (let i = 0; i < camps.length && !encontre; i++) {
            if (camps[i].value === camp) encontre = true;
        }
        setBusIsNotAviable(encontre);
        return encontre;
    };

    const handleSelectionChangeChurch = (e) => {
        const churchSelected = e.target.value;
        setListChurch(new Set([churchSelected]));

        if (churchSelected !== "Otra")
            setChurch(churchSelected);
        else {
            setChurch("");
        }
        setOtherChurch(churchSelected === "Otra");
    };

    const handleSelectionChangeCamp = (e) => {
        const campSelected = e.target.value;
        setListCampsSelected(new Set([campSelected]));

        setCamp(campSelected);
    };

    const getDisabledCamps = () => {
        const disabledCamps = CAMPS_BULK.filter((camp) => !camp.aviable);
        return disabledCamps.map((item) => item.name);
    }

    const handleSelectionChangeDeparture = (e) => {
        const departureSelected = e.target.value;
        setListDepartureSelected(new Set([departureSelected]));

        setDeparture(departureSelected);
    };

    const closeExampleFileModal = () => {
        setViewModalExampleFile(false);
    };

    useEffect(() => {
        setFormValid(areFieldsValid());
    }, [formState, currentStep, items, church, camp, departure, owner]);

    useEffect(() => {
        getCampStatusBus();
    }, [camp]);

    return (
        <FormContentContainer>
            <ExampleFileModal
                visible={viewModalExampleFile}
                onCloseModal={closeExampleFileModal}
            />
            {currentStep === 0 && (
                <LoadFileContainer>
                    <div className="flex flex-col justify-center mb-2 w-full"
                    >
                        <ButtonsBulkContainer>
                            <div className="flex w-full">
                                <Badge
                                    color={"purple"}
                                    text={"Primero:"}
                                />
                                <p className="mx-3">Descarga el archivo excel</p>
                            </div>
                            <a
                                href={fileToDownload}
                                download="AlfaTemplate.xlsx"
                                className="flex items-center justify-center w-full mt-1 shadow-lg bg-yellow-500 rounded-xl cursor-pointer hover:bg-yellow-400 h-14 hover:text-black"
                            >
                                <p>
                                    Descargar archivo
                                </p>
                            </a>
                            <div className="w-full pt-8">
                                <div className="flex items-center">
                                    <Badge
                                        color={"purple"}
                                        text={"Segundo:"}
                                    />
                                    <p className="mx-3">Completá la lista</p>
                                    <Button
                                        className="ml-4"
                                        size="sm"
                                        color="secondary"
                                        onClick={() => setViewModalExampleFile(true)}>
                                        Ver ejemplo
                                    </Button>
                                </div>
                                <div className="flex items-center pt-8">
                                    <Badge
                                        color={"purple"}
                                        text={"Tercero:"}
                                    />
                                    <p className="mx-3">Subír el archivo con acampantes</p>
                                </div>
                                <div class="flex justify-center items-center text-center h-14">
                                    <input type="file" id="file-input" class="sr-only"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            readExcel(file);
                                        }}
                                        accept=".xlsx"
                                    />
                                    <label
                                        for="file-input"
                                        className={`h-full w-full mt-1 shadow-lg ${fileLoaded ? `bg-emerald-400` : `bg-slate-200`} rounded-xl cursor-pointer hover:bg-slate-300`}
                                    >
                                        {!fileLoaded && <p className="mt-4">Cargar archivo</p>}
                                        {fileLoaded &&
                                            <div className="flex items-center w-full justify-center mt-4">
                                                <img src={CircleCheckIcon} alt="check" width="20px" />
                                                <p>{fileName}</p>
                                            </div>
                                        }
                                    </label>
                                    {/* <span id="file-name" class="absolute inset-y-0 right-0 px-4 py-2 border-l border-gray-300 text-gray-500 overflow-hidden"></span> */}
                                </div>
                            </div>
                        </ButtonsBulkContainer>
                    </div>

                    <div className="flex flex-col items-center pt-8">
                        <Chip color="warning" variant="solid">
                            IMPORTANTE: Líderes inscribirse de manera individual
                        </Chip>
                        <div className="p-2">
                            <Chip color="warning" variant="solid">
                                NO incluirlos en esta carga grupal
                            </Chip>
                        </div>
                    </div>

                </LoadFileContainer>
            )}

            {currentStep === 1 && (
                <div className="flex flex-col content-between h-full w-1/2 mt-3">
                    <div className="h-full">
                        {/* <SelectNext
                            isRequired
                            label="Iglesia"
                            placeholder="Seleccionar iglesia"
                            className="my-3"
                            onChange={handleSelectionChangeChurch}
                            selectedKeys={listChurch}
                        >
                            {CHURCHS.map((church) => (
                                <SelectItem key={church} value={church}>
                                    {church}
                                </SelectItem>
                            ))}
                        </SelectNext> */}

                        <div className="flex items-center bg-amber-400 font-bold  rounded-lg p-4 gap-1">
                            <p>Iglesia: </p>
                            <p>{church} </p>
                        </div>



                        {/*    {otherChurch && (
                            <InputNext
                                defaultValue={formState?.groupal[0]?.iglesia}
                                type="text"
                                variant="flat"
                                label="Nombre de la Iglesia"
                                onChange={({ target }) => {
                                    setChurch(target.value);
                                }}
                            />
                        )} */}

                        {/*      <SelectNext
                            isRequired
                            label="Campamento"
                            className="my-3"
                            onChange={handleSelectionChangeCamp}
                            // defaultSelectedKeys={[formState?.individual?.sexo]}
                            selectedKeys={listCampsSelected}
                            disabledKeys={getDisabledCamps()}
                        >
                            {CAMPS_BULK?.map((camp) => (
                                <SelectItem key={camp.name} value={camp.name}>
                                    {camp.name}
                                </SelectItem>
                            ))}
                        </SelectNext> */}

                        <div className="flex items-center bg-amber-400 font-bold  rounded-lg p-4 gap-1 mt-3">
                            <p>Campamento: </p>
                            <p>{camp} </p>
                        </div>

                        <SelectNext
                            isRequired
                            label="¿Dónde suben?"
                            placeholder="Seleccionar lugar"
                            className="my-3"
                            isDisabled={busIsNotAviable}
                            onChange={handleSelectionChangeDeparture}
                            selectedKeys={listDepartureSelected}
                        >
                            {DEPARTURE_OPTIONS?.map((departure) => (
                                <SelectItem key={departure.name} value={departure.name}>
                                    {departure.name}
                                </SelectItem>
                            ))}
                        </SelectNext>
                        {busIsNotAviable &&
                            <Tag style={{ margin: "5px 0px" }} color="red">
                                Bus sin lugares
                            </Tag>
                        }
                        <InputNext
                            defaultValue={formState?.groupal?.length > 0 ? formState?.groupal[0]?.owner : ""}
                            type="text"
                            variant="flat"
                            label="Nombre de Responsable"
                            onChange={({ target }) => {
                                setOwner(target.value);
                            }}
                        />
                    </div>
                </div>
            )}

            <ButtonFormContainer>
                <div style={{ padding: "0px 15px" }}>
                    <Button
                        color="primary"
                        radius="full"
                        size="md"
                        onClick={onBackButton}
                        isDisabled={currentStep === 0}
                    >Volver</Button>
                </div>
                <div style={{ padding: "0px 15px" }}>
                    <Tooltip key="default" color="secondary" content="Siguiente paso" isOpen={currentStep === 0 && nextStepTooltipVisible} showArrow>
                        <div className="flex w-full justify-center pointer my-2">
                            <Button
                                color="primary"
                                radius="full"
                                size="md"
                                onClick={handleOnClick}
                                isDisabled={!fileLoaded || !formValid}
                            >Continuar</Button>
                        </div>
                    </Tooltip>
                </div>
            </ButtonFormContainer>
        </FormContentContainer>
    );
}

export default EditFormGroupal;

import { React, useEffect, useState } from "react";
import InscriptionCenterContainer from "./styled-components/InscriptionCenterContainer";
import { collection, getDocs } from "firebase/firestore";
import { getData } from "../../firebase";
import { Table, Skeleton } from "antd";
import {
    CAMP_BD,
    MODE_INSCRIPTION,
    TYPE_INSCRIPTION,
} from "../inscription/constants";
import UpdateIcon from "../../assets/icons/update.svg";
import { columnsIndividual, columnsMixed } from "./utils/columns";
import { CSVLink } from "react-csv";
import { Card, CardHeader, Spinner, Tab, Tabs } from "@nextui-org/react";
import exportToExcel from "../../utils/exportToExcel";
import SkeletonInscriptionCenter from "./components/SkeletonAutogestion";

function InscriptionCenter({ camp }) {
    const [individualCamp, setIndividualCamp] = useState([]);
    const [individualLeader, setIndividualLeader] = useState([]);
    const [individualOther, setIndividualOther] = useState([]);
    const [busQuantityGoing, setBusQuantityGoing] = useState([]);
    const [busQuantityReturn, setbusQuantityReturn] = useState([]);
    const [mixedModeRows, setMixedModeRows] = useState([]);


    const [refresh, setRefresh] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const isMixedMode =
        camp === CAMP_BD.CAMP_1 ||
        camp === CAMP_BD.CAMP_2 ||
        camp === CAMP_BD.CAMP_3 ||
        camp === CAMP_BD.CAMP_4 ||
        camp === CAMP_BD.CAMP_5;

    useEffect(() => {
        const modeCamp = isMixedMode ? mixedModeRows : individualCamp;
        const tableOnFilter = [
            ...modeCamp,
            ...individualLeader,
            ...individualOther,
        ];
        let busQuantityGoing = 0;
        let busQuantityReturn = 0;
        tableOnFilter.map((x) => {
            if (
                x.bus &&
                x.bus === "Ida y Vuelta" &&
                x.departure !== "Transporte propio"
            ) {
                busQuantityGoing++;
                busQuantityReturn++;
            }
            if (x.bus && x.bus === "Ida" && x.departure !== "Transporte propio") {
                busQuantityGoing++;
            }
            if (x.bus && x.bus === "Vuelta" && x.departure !== "Transporte propio") {
                busQuantityReturn++;
            }
        });
        setBusQuantityGoing(busQuantityGoing);
        setbusQuantityReturn(busQuantityReturn);
    }, [individualCamp, individualLeader, individualOther, mixedModeRows]);

    const hasRows =
        individualCamp.length > 0 ||
        individualLeader.length > 0 ||
        individualOther.length > 0 ||
        mixedModeRows.length > 0;

    const getRowsGroupalsIndividuals = (group, individual) => {
        return [...group, ...individual];
    };

    const handleOnClickUpdate = () => {
        setLoadingData(true);
        setRefresh(!refresh);
    };

    useEffect(() => {
        const getInscriptos = async () => {
            const inscriptionColection = collection(getData(), `${camp}`);

            const inscriptionSnapshot = await getDocs(inscriptionColection);

            const inscriptionListF = inscriptionSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            //Inscripcion por Excel.
            const resultGroupCamp = inscriptionListF.filter(
                (bookId) => bookId.type === TYPE_INSCRIPTION.GROUP
            );

            const mookGroupCamp = [];

            resultGroupCamp.forEach((group) => {
                group.groupal.forEach((element) => {
                    mookGroupCamp.push({
                        bus: element.bus,
                        camp: element.camp,
                        comment: element?.comment,
                        departure: element.departure,
                        dias: element.dias,
                        edad: element.edad,
                        iglesia: element.iglesia,
                        name: element.name,
                        sexo: element.sexo,
                        mode: element.mode,
                        owner: element.owner,
                        date: group.date.toDate().toLocaleDateString("en-GB"),
                        dateOrder: group.date.toDate(),
                        id: group.id,
                    });
                });
            });

            const resultIndividualCamp = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    bookId.individual.mode === MODE_INSCRIPTION.ACAMPANTE
            );

            const mookIndividualCamp = resultIndividualCamp.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            const resultIndividualLeader = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    bookId.individual.mode === MODE_INSCRIPTION.LIDER
            );

            const mookIndividualLeader = resultIndividualLeader.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            const resultIndividualOther = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    [MODE_INSCRIPTION.SERVICIO, MODE_INSCRIPTION.HIJO_LS].includes(
                        bookId.individual.mode
                    )
            );

            const mookIndividualOther = resultIndividualOther.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            if (isMixedMode) {
                setMixedModeRows(
                    getRowsGroupalsIndividuals(mookGroupCamp, mookIndividualCamp)
                );
            }

            setIndividualCamp(mookIndividualCamp);

            setIndividualLeader(mookIndividualLeader);

            setIndividualOther(mookIndividualOther);

            setLoadingData(false);

        };

        setLoadingData(true);
        getInscriptos();

    }, [refresh]);

    if (loadingData) {
        return <SkeletonInscriptionCenter />
    }

    return (
        <InscriptionCenterContainer>
            <div className="flex flex-col">
                <div className="flex flex-col justify-center w-full items-center bg-cyan-200 rounded-3xl p-3 mb-6">
                    <div className="flex items-center">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full items-center justify-center text-2xl gap-x-2 mb-3">
                                <p className=""> Campamento:</p> <p className="uppercase font-bold border border-black rounded-2xl p-2">{camp}</p>
                                {!loadingData && <img onClick={handleOnClickUpdate} className="cursor-pointer" src={UpdateIcon} alt="update" width={25}></img>}
                                {loadingData && <Spinner color="primary" />}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-center w-full md:max-w-4xl">
                        <Card className="py-4 m-1 w-full bg-lime-400">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Acampantes:</p>
                                <h4 className="font-bold text-large">{isMixedMode ? mixedModeRows.length : individualCamp.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Líderes:</p>
                                <h4 className="font-bold text-large">{individualLeader.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Otros:</p>
                                <h4 className="font-bold text-large">{individualOther.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Bus IDA:</p>
                                <h4 className="font-bold text-large">{busQuantityGoing}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold">Bus VUELTA:</p>
                                <h4 className="font-bold text-large">{busQuantityReturn}</h4>
                            </CardHeader>
                        </Card>

                        <Card className="py-4 m-4 bg-amber-300 w-full">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-center">
                                <p className="text-tiny uppercase font-bold text-center">Total:</p>
                                <h4 className="font-bold text-large text-blue-600">{
                                    (isMixedMode ? mixedModeRows.length : individualCamp.length) +
                                    individualLeader.length +
                                    individualOther.length
                                }</h4>
                            </CardHeader>
                        </Card>
                    </div>
                </div>


                <div className="hidden md:flex flex-col items-center align-center">
                    <Tabs aria-label="Options" size="lg">
                        <Tab key="camps" title="Acampantes">
                            <div className="text-center">
                                <button className="bg-green-500 hover:bg-green-400 rounded-lg p-2 my-4" onClick={() => exportToExcel(isMixedMode ? mixedModeRows : individualCamp)}>Descargar individuales</button>
                            </div>
                            <div className="max-h-105 overflow-y-scroll px-2">
                                <Table
                                    columns={isMixedMode ? columnsMixed : columnsIndividual}
                                    dataSource={isMixedMode ? mixedModeRows : individualCamp}
                                    size="middle"
                                    pagination={false}
                                />
                            </div>
                        </Tab>
                        <Tab key="leader" title="Líderes">
                            <div className="text-center">
                                <button className="bg-green-500 hover:bg-green-400 rounded-lg p-2 my-4" onClick={() => exportToExcel(individualLeader)}>Descargar líderes</button>
                            </div>
                            <div className="max-h-105 overflow-y-scroll px-2">
                                <Table
                                    columns={columnsIndividual}
                                    dataSource={individualLeader}
                                    size="middle"
                                    pagination
                                    scroll
                                />
                            </div>
                        </Tab>
                        <Tab key="others" title="Otros">
                            <div className="text-center">
                                <button className="bg-green-500 hover:bg-green-400 rounded-lg p-2 my-4" onClick={() => exportToExcel(individualOther)}>Descargar otros</button>
                            </div>
                            <div className="max-h-105 overflow-y-scroll">
                                <Table
                                    columns={columnsIndividual}
                                    dataSource={individualOther}
                                    size="middle"
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </InscriptionCenterContainer>
    );
}

export default InscriptionCenter;

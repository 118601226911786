import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { React, useContext, useEffect } from "react";
import { CAMPS, GENERAL_CAMPS } from "../../constants";
import { useNavigate } from "react-router-dom";
import CardsCamp from "./components/CardsCamp";
import { InscriptionContext } from "../../../../provider/inscription";


function SelectChurch() {
    const navigateTo = useNavigate();
    const { fetchCam, clearKey } = useContext(InscriptionContext);

    useEffect(()=> {
        clearKey();
    },[])

    const findIdAndNavigate = (arrayIds, campId) => {
        let isGeneralCamp = false;

        const camp = CAMPS.find(camp => camp.id === campId);
        const campName = camp.name;
        fetchCam(campName);

        arrayIds.forEach(generalCampId => {
            if (generalCampId === campId) {
                isGeneralCamp = true;
            }
        });
        if (isGeneralCamp)
            navigateTo("/general")
        else {
            navigateTo("/inscription/type")
        }
    }


    const onSelectCamp = (id) => {
        findIdAndNavigate(GENERAL_CAMPS, id)
    }

    return (
        <div className="flex flex-col w-full items-center h-full md:content-center justify-center self-center">
            <p className="text-2xl md:text-3xl font-bold my-10">Seleccionar campamento: </p>
            <div className="overflow-y-scroll overflow-x-hidden h-100 mb-6 mx-2">
                {CAMPS.map((camp) => (
                    <CardsCamp camp={camp} onSelectCamp={onSelectCamp} />
                ))}
            </div>
        </div>
    );
}

export default SelectChurch;

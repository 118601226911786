import React from "react";
import { Button, Tooltip } from "@nextui-org/react";
import WsspIcon from "../../../../assets/icons/wssp.svg"

const ContactButton = () => {
  return (
    <div className="flex items-center gap-x-1 mb-3 border border-yellow-700 rounded-2xl shadow-lg p-3 ">
      <p>Si ténes dudas, consultá en:</p>
      <Button
        color="success"
        variant="shadow"
        startContent={<img src={WsspIcon} alt="wssp" width={20} />}
        className="text-white m-2"
        onClick={() => window.open("https://chat.whatsapp.com/LOObfsvZIWGLhPGxp1Wqzk", "_blank")}
      >
        Grupo de WhatsApp
      </Button>
    </div>
  );
};

export default ContactButton;

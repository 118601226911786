import styled from "styled-components";

// Styled component named StyledButton
const ImageStyled = styled.img`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
`;

export default ImageStyled;

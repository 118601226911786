import { Card, Skeleton } from "@nextui-org/react";

const cardsCountFake = [1, 2, 3, 4, 5];
const rowsFake = [1, 2, 3, 4, 5, 6, 7];

function SkeletonInscriptionCenter() {
    return (
        <div className="flex flex-col w-full items-center mt-10">
            <Skeleton className="h-3 w-3/5 rounded-lg" />
            <Skeleton className="h-3 w-2/5 rounded-lg mt-3" />
            <div className="flex mt-4 justify-center">
                {cardsCountFake.map((i) => (
                    <div className="flex mx-3">
                        <Card className="space-y-5 p-4" radius="lg">
                            <div className="max-w-[300px] w-full flex items-center gap-3">
                                <div>
                                    <Skeleton className="flex rounded-full w-12 h-12" />
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <Skeleton className="h-3 w-3/5 rounded-lg" />
                                    <Skeleton className="h-3 w-4/5 rounded-lg" />
                                </div>
                            </div>
                            <div className="space-y-3">
                                <Skeleton className="w-full rounded-lg">
                                    <div className="h-3 w-full rounded-lg bg-secondary-300"></div>
                                </Skeleton>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
            <div className="bg-white md:w-3/4 h-80 mt-6">
                <div className="w-full flex flex-col gap-2 mt-4">
                    {rowsFake.map((i) => (
                        <div className="flex flex-col gap-2">
                            <Skeleton className="h-3 w-3/5 rounded-lg" />
                            <Skeleton className="h-3 w-4/5 rounded-lg" />
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default SkeletonInscriptionCenter;

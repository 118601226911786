import { useContext, useEffect, useState } from "react";
import EditFormIndividualContainer from "./styled-components/EditFormIndividualContainer";
import {
    BUS_FULL,
} from "../../constants";
import ButtonFormContainer from "./styled-components/ButtonFormContainer";
import FormContentContainer from "./styled-components/FormContentContainer";
import { Button } from "@nextui-org/react";
import PersonalInformation from "./components/PersonalInformation";
import CampInformation from "./components/CampInformation";
import AditionalInformation from "./components/AditionalInformation";
import { InscriptionContext } from "../../../../provider/inscription";
import { useNavigate } from "react-router-dom";

function EditFormIndividual({ currentStep, setStep, formState, setFormState, keyData, isGeneralInscription }) {
    const [formValid, setFormValid] = useState(false);
    const [busIsNotAviable, setBusIsNotAviable] = useState();
    const { inscriptionKey, campSelected } = useContext(InscriptionContext);
    const navigateTo = useNavigate();

    const onBackButton = () => {
        if(isGeneralInscription){
            navigateTo("/inscription")
        }
        setStep(currentStep - 1);
    };

    const handleOnClick = () => {
        if (currentStep === 0) {
            setFormState({
                ...formState.individual,
                iglesia: keyData?.church || "",
                camp: keyData?.camp || campSelected
            });
        }
        setStep(currentStep + 1);
    };

    const areFieldsValid = () => {
        if (currentStep === 0) {
            return (
                !!formState?.individual?.name &&
                !!formState?.individual?.ci &&
                !!formState?.individual?.edad &&
                !!formState?.individual?.sexo &&
                !!formState?.individual?.celular
            );
        }
        if (currentStep === 1) {
            return (
                !!formState?.individual?.iglesia &&
                !!formState?.individual?.dias &&
                !!formState?.individual?.camp &&
                !!formState?.individual?.mode
            );
        }
        if (currentStep === 2) {
            return (
                (formState?.individual?.bus === "No" ||
                    formState?.individual?.bus === "Vuelta" ||
                    busIsNotAviable
                    ? true
                    : !!formState?.individual?.departure)
            );
        }
    };

    const getCampStatusBus = () => {
        const { camps } = BUS_FULL;
        let encontre = false;
        for (let i = 0; i < camps.length && !encontre; i++) {
            if (camps[i].value === formState?.individual?.camp) encontre = true;
        }
        setBusIsNotAviable(encontre);
        setFormState({
            ...formState.individual,
            bus: encontre ? "No" : "",
            departure: null,
        });
        return encontre;
    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getCampStatusBus();
    }, [formState?.individual?.camp]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setFormValid(areFieldsValid());
    }, [formState, currentStep]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setFormState({
            ...formState.individual,
            bus:
                typeof formState?.individual?.bus !== "undefined" &&
                formState?.individual?.bus,
        });
    }, [formState?.individual?.bus]);


    return (
        <EditFormIndividualContainer className="h-[42rem] md:h-[90%]">
            <FormContentContainer className="md:max-h-[416px] md:overflow-x-hidden md: overflow-y-scroll">
                {currentStep === 0 && (
                    <PersonalInformation formState={formState} setFormState={setFormState} />
                )}

                {currentStep === 1 && (
                    <CampInformation
                        formState={formState}
                        setFormState={setFormState}
                        setBusIsNotAviable={setBusIsNotAviable}
                        camp={formState?.individual?.camp}
                        inscriptionKey={inscriptionKey}
                    />
                )}

                {currentStep === 2 && (
                    <AditionalInformation
                        formState={formState}
                        setFormState={setFormState}
                    />
                )}
            </FormContentContainer>

            <ButtonFormContainer className="my-10 mx-0.5 md:m-0">
                {(currentStep > 0 || isGeneralInscription) &&
                    <div style={{ padding: "0px 15px" }}>
                        <Button
                            color="primary"
                            radius="full"
                            size="md"
                            onClick={onBackButton}
                        >
                            Volver
                        </Button>
                    </div>
                }
                <div style={{ padding: "0px 15px" }}>
                    <Button
                        color="primary"
                        radius="full"
                        size="md"
                        onClick={handleOnClick}
                        isDisabled={!formValid}
                    >
                        Siguiente
                    </Button>
                </div>
            </ButtonFormContainer>
            {/*</Form>*/}
        </EditFormIndividualContainer>
    );
}

export default EditFormIndividual;

import { React, useState } from "react";
import EditFormIndividual from "../EditFormIndividual";
import Checkout from "../Checkout";
import IndividualContainer from "./styled-components/IndividualContainer";

function Individual({ currentStep, setStep, formState, modeReinscription, keyData, isGeneralInscription }) {
    const [wholeFormState, setWholeFormState] = useState(formState || {});
    const finalStep = 3;

    return (
        <IndividualContainer className="md:h-full overflow-y-scroll">
            {currentStep !== finalStep && (
                <EditFormIndividual
                    currentStep={currentStep}
                    setStep={setStep}
                    formState={wholeFormState}
                    setFormState={(individualData) => {
                        setWholeFormState({
                            ...wholeFormState,
                            individual: {
                                ...individualData,
                                key: keyData?.key || null
                            },
                        });
                    }}
                    keyData={keyData}
                    isGeneralInscription={isGeneralInscription}
                ></EditFormIndividual>
            )}
            {currentStep === finalStep && (
                <Checkout
                    currentStep={currentStep}
                    setStep={setStep}
                    formState={wholeFormState}
                    setFormState={(price) => {
                        setWholeFormState({
                            ...wholeFormState,
                            price: price,
                        });
                    }}
                    modeReinscription={modeReinscription}
                />
            )}
        </IndividualContainer>
    );
}

export default Individual;

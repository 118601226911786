import { React, useState } from "react";
import { CAMP_BD } from "../../../inscription/constants";
import InscriptionCenter from "../../InscriptionCenter";
import { Typography } from "antd";
import LeftIcon from "../../../../assets/icons/left-icon.svg"
import ArrowCircle from "../../../../assets/icons/rigth-circle.svg"


function SelectTable({ goBack }) {
  const [tableSelected, setTableSelected] = useState("");

  const handleOnClickTable1 = () => {
    setTableSelected(CAMP_BD.CAMP_1);
  };
  const handleOnClickTable2 = () => {
    setTableSelected(CAMP_BD.CAMP_2);
  };
  const handleOnClickTable3 = () => {
    setTableSelected(CAMP_BD.CAMP_3);
  };
  const handleOnClickTable4 = () => {
    setTableSelected(CAMP_BD.CAMP_4);
  };
  const handleOnClickTable5 = () => {
    setTableSelected(CAMP_BD.CAMP_5);
  };
  const handleOnClickTable6 = () => {
    setTableSelected(CAMP_BD.CAMP_6);
  };
  const handleOnClickTable7 = () => {
    setTableSelected(CAMP_BD.CAMP_7);
  };
  const handleOnClickTable8 = () => {
    setTableSelected(CAMP_BD.CAMP_8);
  };
  const handleOnResetTable = () => {
    setTableSelected("");
  };

  const buttons = [
    {
      tag: "Niños",
      action: handleOnClickTable1,
    },
    {
      tag: "Pre",
      action: handleOnClickTable2,
    },
    {
      tag: "Adol A",
      action: handleOnClickTable3,
    },
    {
      tag: "Adol B",
      action: handleOnClickTable4,
    },
    {
      tag: "Jov A",
      action: handleOnClickTable5,
    },
    {
      tag: "Jov B",
      action: handleOnClickTable6,
    },
    {
      tag: "Antiestrés",
      action: handleOnClickTable7,
    },
    {
      tag: "Familias",
      action: handleOnClickTable8,
    },
  ]

  return (
    <div className="flex flex-col items-center self-center content-center justify-center w-full">
      <div className="flex">
        <button onClick={goBack} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <img src={LeftIcon} width={30} alt="lefticon" />
          <p className="mx-1">Inicio</p>
        </button>
        {!!tableSelected &&
          <div className="flex gap-2">
            <button onClick={handleOnResetTable} className="bg-orange-500 rounded-xl w-full shadow-lg text-white p-4 hover:bg-orange-400 my-1">Cambiar campamento</button>
          </div>
        }
      </div>
      {!tableSelected ? (
        <div className="md:w-1/4 w-full p-3">
          <p className="text-2xl font-bold text-center mb-3"> Seleccionar campamento:</p>
          <div className="border rounded-2xl border-amber-600 mt-4 p-4">
            {buttons.map((btn) => (
              <div
                onClick={btn.action}
                className="flex rounded-xl px-10 justify-between w-full text-black p-6 bg-orange-500 hover:bg-orange-400 shadow-md my-1 cursor-pointer"
              >
                <p className="text-white">{btn.tag}</p>
                <button className="flex gap-x-1">
                  <p>Consultar</p><img src={ArrowCircle} width={20}></img>
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <InscriptionCenter
          camp={tableSelected}
          resetTableSelected={handleOnResetTable}
        ></InscriptionCenter>
      )}
    </div>
  );
}

export default SelectTable;

import * as XLSX from 'xlsx';

const exportToExcel = (dataExport) => {
    const worksheet = XLSX.utils.json_to_sheet(dataExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,
        worksheet, 'Data');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'alfa.xlsx');
    document.body.appendChild(link);
    link.click();

    link?.parentNode?.removeChild(link);

};
export default exportToExcel;

import { React, useContext, useEffect, useState } from "react";
import InscriptionManagerContainer from "./styled-components/InscriptionManagerContainer";
import {
    Badge,
} from "antd";
import Wizard from "../Wizard";
import LeftIcon from "../../../../assets/icons/left-icon.svg"
import TypeTitle from "./styled-components/TypeTitle";
import BadgeSubtitle from "./styled-components/BadgeSubtitle";
import { TYPE_INSCRIPTION } from "../../constants";
import PrivateSectionModal from "../../../../components/PrivateSectionModal";
import CardsGestionBulk from "./CardsCamp/CardsGestionBulk";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { getData } from "../../../../firebase";
import { InscriptionContext } from "../../../../provider/inscription";
import ContactButton from "../ContactButton/ContactButton";

const optionsCards = [
    {
        id: 1,
        title: "Individual",
        items: ["Inscribir únicamente a un acampante.", "Inscribir a un líder."]
    },
    {
        id: 2,
        title: "Grupal",
        items: ["Inscribir a varios acampantes en un solo paso."]
    },
    {
        id: 3,
        title: "Gestión",
        items: ["Consultá y gestioná tus inscripciones."]
    },
]

function InscriptionManager() {
    const [wizzardActive, setWizzardActive] = useState("");
    const [modalIsVisible, setModalIsVisible] = useState(true);
    const [keyIsInvalid, setKeyIsInvalid] = useState(false);
    const [campIsInvalid, setCampIsInvalid] = useState(false);
    const [keyData, setKeyData] = useState(false);
    const [loadingKey, setLoadingKey] = useState(false);
    const [isPrivateMode, setIsPrivateMode] = useState(false);
    const { campSelected, fetchKey, inscriptionKey } = useContext(InscriptionContext);


    const onSelectCard = (id) => {
        if (id === 0) setWizzardActive("")
        if (id === 1) setWizzardActive(TYPE_INSCRIPTION.INDIVIDUAL)
        if (id === 2) setWizzardActive(TYPE_INSCRIPTION.GROUP)
        if (id === 3) setWizzardActive(TYPE_INSCRIPTION.AUTOGESTION)
    }

    const onValidateKey = async (key) => {
        setLoadingKey(true);
        setCampIsInvalid(false)
        setKeyIsInvalid(false)
        const snapKey = await getDoc(doc(getData(), "keys", `${key}`));
        const data = snapKey.data();

        if (snapKey.exists()) {
            if (campSelected === data.camp) {
                fetchKey(key);
                setKeyData({
                    key: key,
                    ...data
                })

                setModalIsVisible(false);
                setKeyIsInvalid(false);
            }
            else {
                setCampIsInvalid(true);
            }

        } else {
            setKeyIsInvalid(true);
        }
        setLoadingKey(false);
    };

    const onGoHome = () => {
        if (!!keyData) onSelectCard(0)
        else {
            setIsPrivateMode(false)
            setModalIsVisible(true)
        }
    }


    return (
        <InscriptionManagerContainer className="md:h-full">
            <PrivateSectionModal
                visible={modalIsVisible}
                isLoading={loadingKey}
                onFecthKey={onValidateKey}
                keyIsInvalid={keyIsInvalid}
                campIsInvalid={campIsInvalid}
                onCloseModal={() => setModalIsVisible(false)}
                onSelectCard={onSelectCard}
                isPrivateMode={isPrivateMode}
                setIsPrivateMode={setIsPrivateMode}
                campSelected={campSelected}
            />
            <div className="flex flex-col md:justify-center md:items-center w-full h-full">
                <div className="flex flex-col md:flex-row items-center gap-4 mt-3">
                    {!!wizzardActive &&
                        <button onClick={onGoHome} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <img src={LeftIcon} width={30} alt="lefticon" />
                            <p className="mx-1">Inicio</p>
                        </button>
                    }
                    <div>
                        {!!wizzardActive && (
                            <TypeTitle className="text-2xl md:text-3xl" level={5}>ALFA 2025</TypeTitle>
                        )}
                        {!wizzardActive ? (
                            <div className="flex flex-col gap-x-2 items-center">
                                <button className="flex gap-x-1 mb-2" onClick={() => setModalIsVisible(true)}>
                                    <p>Referencia: </p><p className="font-extrabold text-orange-700">{inscriptionKey}</p>
                                    <p className="underline">Cambiar</p>
                                </button>
                                <Badge
                                    color={"purple"}
                                    text={"Selecioná el tipo de inscripción"}
                                />
                            </div>
                        ) : (
                            <BadgeSubtitle>
                                {wizzardActive === TYPE_INSCRIPTION.INDIVIDUAL &&
                                    <Badge
                                        color={"purple"}
                                        text={"#DiosEsFiel"}
                                    />
                                }
                            </BadgeSubtitle>
                        )}
                    </div>
                </div>
                {!wizzardActive ? (
                    <div className="flex flex-col items-center">
                        <div className="md:flex p-4">
                            {optionsCards.map((option) => (
                                <CardsGestionBulk options={option} onSelectType={onSelectCard} />
                            ))}
                        </div>
                        <ContactButton />
                    </div>
                ) : (
                    <Wizard type={wizzardActive} keyData={keyData}></Wizard>
                )}
            </div>
        </InscriptionManagerContainer>
    );
}

export default InscriptionManager;

const columns = [
  { name: "Nombre", uid: "name", sortable: true },
  { name: "Dias", uid: "dias" },
  { name: "Bus", uid: "bus", sortable: true },
  { name: "Inscripción", uid: "date" },
  { name: "Acciones", uid: "actions" }
];

const statusOptions = [
  { name: "Active", uid: "active" },
  { name: "Paused", uid: "paused" },
  { name: "Vacation", uid: "vacation" },
];

export { columns, statusOptions };
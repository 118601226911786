import {Result, Button} from "antd";
import {Link} from "react-router-dom";
import {Card, CardBody} from "@nextui-org/react";

export default function SendFail({title, subTitle}) {
    return (
        <Card>
            <CardBody>
                <Result
                    status="error"
                    title={title}
                    subTitle={subTitle}
                />
            </CardBody>
        </Card>

    );
}

import { React } from "react";
import InscriptionManagerContainer from "./styled-components/InscriptionManagerContainer";
import {
    Divider,
    Badge,
} from "antd";
import Wizard from "../Wizard";
import TypeTitle from "./styled-components/TypeTitle";
import BadgeSubtitle from "./styled-components/BadgeSubtitle";
import { TYPE_INSCRIPTION } from "../../constants";

function GeneralInscription() {
    return (
        <InscriptionManagerContainer className="md:h-full">
            <div className="flex flex-col md:flex-row md:justify-center md:items-center w-full h-full">
                <div>
                    <TypeTitle className="text-2xl md:text-3xl md:mx-14" level={5}>ALFA 2025</TypeTitle>
                    <BadgeSubtitle>
                        <Badge
                            color={"purple"}
                            text={"#DiosEsFiel"}
                        />
                    </BadgeSubtitle>
                    <Divider></Divider>
                </div>
                <Wizard type={TYPE_INSCRIPTION.INDIVIDUAL} isGeneralInscription></Wizard>
            </div>
        </InscriptionManagerContainer>
    );
}

export default GeneralInscription;

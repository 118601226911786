import styled from "styled-components";
import {device} from "../../../utils/device";

// Styled component named StyledButton
const TitleHomeContainer = styled.div`
  color: rgb(205 36 81);
  text-align: center;
  font-style: bold;
  && p {
    color: #e85179;
    margin: 0;
  }
  font-weight: bold;
`;

export default TitleHomeContainer;

import {useEffect, useState} from "react";
import {
    DEPARTURE_OPTIONS,
    BUS_FULL,
} from "../../../../constants";
import {Select, SelectItem, Textarea} from "@nextui-org/react";
import RadioGroupBus from "./components/RadioGroupBus";


function AditionalInformation({formState, setFormState}) {
    const [busIsNotAviable, setBusIsNotAviable] = useState();
    const [listDepartureSelected, setListDepartureSelected] = useState(new Set([formState?.individual?.departure || '']));

    const showDepartureBus =
        !!formState?.individual?.bus &&
        formState?.individual?.bus !== "No" &&
        formState?.individual?.bus !== "Vuelta";

    const getCampStatusBus = () => {
        const {camps} = BUS_FULL;
        let encontre = false;
        for (let i = 0; i < camps.length && !encontre; i++) {
            if (camps[i].value === formState?.individual?.camp) encontre = true;
        }
        setBusIsNotAviable(encontre);
        setFormState({
            ...formState.individual,
            bus: encontre ? "No" : "",
            departure: null,
        });
        return encontre;
    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getCampStatusBus();
    }, [formState?.individual?.camp]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setFormState({
            ...formState.individual,
            bus:
                typeof formState?.individual?.bus !== "undefined" &&
                formState?.individual?.bus,
        });
    }, [formState?.individual?.bus]);


    const handleSelectionChangeDeparture = (e) => {
        const departureSelected = e.target.value;
        setListDepartureSelected(new Set([departureSelected]));

        setFormState({
            ...formState.individual,
            departure: departureSelected
        });
    };


    return (
        <div className="flex flex-col w-full">
            <RadioGroupBus
                disabled={busIsNotAviable}
                onChangeRadioGroup={(target) => {
                    setFormState({
                        ...formState.individual,
                        bus: target[0],
                        departure: null,
                    });
                    setListDepartureSelected(new Set([]))
                }}
                valueSelected={formState?.individual?.bus}
            />

            {showDepartureBus && !busIsNotAviable && (
                <Select
                    isRequired
                    label="¿Dónde subís?"
                    placeholder="Seleccionar lugar"
                    className="my-3 md:max-w-md"
                    onChange={handleSelectionChangeDeparture}
                    selectedKeys={listDepartureSelected}
                >
                    {DEPARTURE_OPTIONS?.map((departure) => (
                        <SelectItem key={departure.name} value={departure.name}>
                            {departure.name}
                        </SelectItem>
                    ))}
                </Select>
            )}

            <Textarea
                label="Comida/Medicación (Opcional)"
                labelPlacement="outside"
                placeholder="Celiaco, Diabetico, Medicaciones a tener en cuenta, etc..."
                className="my-3"
                onChange={({target}) => {
                    setFormState({
                        ...formState.individual,
                        comment: target.value,
                    });
                }}
                defaultValue={formState?.individual?.comment}
            />
        </div>
    );
}

export default AditionalInformation;
